<div class="page-wrapper">
    <div class="login-bg row p-30">
        <div class="text-center col-xl-6">
            <img src="assets/images/bentex-logo.png" alt="">
        </div>
        <div class="col-xl-6 ">
            <div class="card-body login-box">
                <div class=" mb-5">
                    <h1 class="text-white">Login</h1>
                </div>
                <form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
                    <div class="form-group mb-4">
                        <input class="login-input mb-2" formControlName="email" type="email" required="" placeholder="Enter Email">
                        <label class="field-text">Username or Email</label>
                        <div class="text-white" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">
                            Email is required
                        </div>
                        <div class="text-white" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">
                            Invalid Email
                        </div>
                    </div>
                    <div class="form-group">
                        <input class="login-input mb-2" formControlName="password" type="password" required="" placeholder="Enter Password">
                        <label class="field-text">Password</label>
                        <div class="text-white" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">
                            Password is required
                        </div>
                    </div>
                    <div class="checkbox p-0 mb-4">
                        <input id="checkbox1" type="checkbox">
                        <label for="checkbox1"><span class="text-white">Remember me</span></label>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <label class="reset-text">*Forgot your password?</label>
                            <label class="reset-link pl-3">Reset Here</label>
                        </div>
                    </div>
                    <div class="mb-5">
                        <button class="login-btn" [class.loader--text]="authService.showLoader" [disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)" type="submit"><span class="text-white">{{ authService.showLoader ? '' : 'Login' }}<i
									class="fa fa-caret-right pl-3"></i></span>
						</button>
                    </div>

                    <!--
                        <div>
                        <p class="field-text ">** For testing You Can Login with Google **
                            <p>
                    </div>
                       <div class="social mt-3">
                        <div class="form-group  google-btn btn-showcase d-flex">
                            <button class="btn btn-google d-inline-block" (click)="loginGoogle()">
								<i class="fa fa-google"></i>
							</button>
                        </div>
                    </div> 
                    -->


                </form>
            </div>

        </div>
    </div>
</div>


<!--
	<div class="page-wrapper">
    <div class="auth-bg">
        <div class="authentication-box">
            <div class="text-center">
                <img src="assets/images/bentex-logo.png" alt="">
            </div>
            <div class="card mt-4">
                <div class="card-body">
                    <div class="text-center">
                        <h4>LOGIN</h4>
                        <h6>Enter your Username and Password </h6>
                    </div>
                    <form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
                        <div class="form-group">
                            <label class="col-form-label pt-0">Email</label>
                            <input class="form-control" formControlName="email" type="email" required="">
                            <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">
                                Email is required
                            </div>
                            <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">
                                Invalid Email
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">Password</label>
                            <input class="form-control" type="password" formControlName="password" required="">
                            <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">
                                Password is required
                            </div>
                        </div>
                        <div class="checkbox p-0">
                            <input id="checkbox2" type="checkbox">
                            <label for="checkbox2">Remember me</label>
                        </div>
                        <div class="form-group form-row mt-3 mb-0">
                            <button class="btn btn-primary btn-block btn-lg" [class.loader--text]="authService.showLoader" [disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)" type="submit"><span>{{ authService.showLoader ? '' : 'Login' }}</span>
							</button>
                        </div>
                        <div class="login-divider"></div>
                        <div class="social mt-3">
                            <div class="form-group btn-showcase d-flex">
                                <button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
									<i class="fa fa-facebook"></i>
								</button>
                                <button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
									<i class="fa fa-twitter"></i>
								</button>
                                <button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
									<i class="fa fa-google"></i>
								</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
-->