import { Routes } from '@angular/router';

export const content: Routes = [
  
  {
    path: 'modals',
    loadChildren: () => import('../../components/modals/modals.module').then(m => m.ModalsModule),
    data: {
      breadcrumb: "Neworder"
    }
  },
  
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/orders.module').then(m => m.OrdersModule),
    data: {
      breadcrumb: "Orders"
    }
  },
  {
    path: 'customers',
    loadChildren: () => import('../../components/customers/customers.module').then(m => m.CustomersModule),
    data: {
      breadcrumb: "Customers"
    }
  },
  {
    path: 'quotes',
    loadChildren: () => import('../../components/quotes/quotes.module').then(m => m.QuotesModule),
    data: {
      breadcrumb: "Customers"
    }
  }
];