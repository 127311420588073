import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
//import { environmentDev } from './environments/environment.dev';
import { environment } from './environments/environment.prod';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  let channel = new BroadcastChannel('tab');

  channel.postMessage('another-tab');
  // note that listener is added after posting the message

  channel.addEventListener('message', (msg) => {
    if (msg.data === 'another-tab') {
      // message received from 2nd tab
      alert('Cannot open multiple instances');
    }
  });