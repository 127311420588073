import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'keyValue'
})
export class KeyValuePipe implements PipeTransform {

  transform(value: any): any[] {
    let resultArray = [];
    Object.keys(value).map(function (styleIndex) {

      if (value[styleIndex]) {
        let style = {
          ...value[styleIndex],
          styleId: styleIndex
        };
        resultArray.push(style)
      }
    });
    console.log(resultArray)
    return resultArray;
  }
}
