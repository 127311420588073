export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDdrI9zUpXI5yw8vn9PnrfHOKMDz37WdIo",
    authDomain: "auth-test-95cff.firebaseapp.com",
    databaseURL: "https://auth-test-95cff-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "auth-test-95cff",
    storageBucket: "auth-test-95cff.appspot.com",
    messagingSenderId: "29410631926",
    appId: "1:29410631926:web:f309bfb53d3242d852ccd7",
    measurementId: "G-SQFW6VH8QR"
  }
};